<template>
  <v-row
    justify="center"
    align="center"
  >
    <v-col
      cols="5"
      lg="4"
    >
      <v-timeline
        :class="getStatusForTime(timePeriod) === 'current' ? 'activehistorycard' : ''"
        :side="$vuetify.display.xs ? '' : 'start'"
        truncate-line="both"
        density="compact"
        class="pl-0 ml-0"
      >
        <v-timeline-item
          v-if="timePeriod.end && timePeriod.end !== '2099-12-31'"
          fill-dot
          :size="getStatusForTime(timePeriod) === 'current' ? '12' : 'xx-small'"
          :dot-color="getDotColor(getStatusForTime(timePeriod))"
        >
          <div
            :class="
              getStatusForTime(timePeriod) === 'past'
                ? 'text-grey text-body-2'
                : 'text-primary text-body-2'
            "
          >
            <span :class="getStatusForTime(timePeriod) === 'current' ? 'biggerFontWeight' : ''">
              {{ formatDateMonth(timePeriod.end) }}&nbsp;{{ formatDateYear(timePeriod.end) }}
            </span>
          </div>
        </v-timeline-item>
        <v-timeline-item
          fill-dot
          :size="getStatusForTime(timePeriod) === 'current' ? '12' : 'xx-small'"
          :dot-color="getDotColor(getStatusForTime(timePeriod))"
        >
          <div
            :class="
              getStatusForTime(timePeriod) === 'past'
                ? 'text-grey text-body-2'
                : 'text-primary text-body-2'
            "
          >
            <span :class="getStatusForTime(timePeriod) === 'current' ? 'biggerFontWeight' : ''">
              {{ timePeriod.end ? '' : 'ab ' }}{{ formatDateMonth(timePeriod.start) }}&nbsp;{{
                formatDateYear(timePeriod.start)
              }}
            </span>
          </div>
        </v-timeline-item>
      </v-timeline>
    </v-col>
    <v-col>
      <v-card
        :elevation="getStatusForTime(timePeriod) === 'current' ? 5 : 0"
        color="primary"
        class="schedulehistorycard"
        :height="approvals && timePeriod.user ? null : 'auto'"
        variant="outlined"
        data-cy="timePeriodCard"
        :ripple="hasRole(['ADMINISTRATIVE_STAFF']) && timePeriod.status !== 'requested'"
        @click="$emit('update')"
      >
        <v-card-item>
          <v-card-subtitle v-if="approvals && timePeriod.user"
            >{{ formatUserName(timePeriod.user) }}
          </v-card-subtitle>
          <v-card-title class="pl-1 pt-1 text-primary d-block">
            {{ timePeriod.text }}
          </v-card-title>
          <div
            v-if="hasRole(['ADMINISTRATIVE_STAFF'])"
            class="position-top-right"
          >
            <v-tooltip
              v-if="
                timePeriod.status === 'approved' &&
                substitutableTypes.includes(timePeriod.type) &&
                !substituteEntries.length
              "
              location="top"
            >
              <template #activator="{ props }">
                <v-btn
                  class="float-right"
                  icon
                  color="transparent"
                  variant="flat"
                  v-bind="props"
                  size="small"
                  @click.stop="$emit('substitute')"
                >
                  <v-icon
                    color="primary"
                    size="large"
                    >mdi-account-switch</v-icon
                  >
                </v-btn>
              </template>
              <span>Vertretung auswählen</span>
            </v-tooltip>

            <v-tooltip
              v-if="substituteEntries.length"
              location="top"
            >
              <template #activator="{ props }">
                <v-btn
                  class="float-right"
                  icon
                  color="transparent"
                  variant="flat"
                  size="small"
                  v-bind="props"
                >
                  <v-icon
                    color="success"
                    size="large"
                    >mdi-check</v-icon
                  >
                </v-btn>
              </template>
              <span>Vertretung durch {{ substituteNames }}</span>
            </v-tooltip>
          </div>
        </v-card-item>
        <v-card-text class="mt-0">
          <v-row
            no-gutters
            align="center"
            justify="space-between"
          >
            <v-col
              v-if="timePeriod.end && timePeriod.type !== 'TERMINATION'"
              cols="12"
            >
              <div class="mb-2">
                <v-icon icon="mdi-clock-outline"></v-icon>
                &nbsp;
                <span> {{ calculateMonthsDifference(timePeriod.start, timePeriod.end) }} </span>
              </div>
            </v-col>
            <v-col
              v-if="timePeriod.type in typeDisplayText"
              class="mr-1"
              cols="auto"
            >
              <v-chip
                v-if="!$vuetify.display.xs"
                size="small"
              >
                {{ typeDisplayText[timePeriod.type] }}</v-chip
              >
            </v-col>
            <v-col
              v-if="!$vuetify.display.xs"
              cols="auto"
            >
              <v-chip size="small"> {{ timePeriod.percentage }}% verfügbar </v-chip>
            </v-col>
            <v-spacer />
            <v-col
              v-if="showStatusButtons"
              cols="auto"
            >
              <v-tooltip location="top">
                <template #activator="{ props }">
                  <v-btn
                    icon
                    v-bind="props"
                    variant="flat"
                    color="transparent"
                    size="small"
                    data-cy="editTimePeriodCard"
                    @click.stop="$emit('edit', timePeriod, 'timePeriod')"
                  >
                    <v-icon
                      color="primary"
                      size="x-large"
                    >
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>Bearbeiten</span>
              </v-tooltip>
            </v-col>
            <v-col
              v-if="showStatusButtons"
              cols="auto"
            >
              <v-tooltip location="top">
                <template #activator="{ props }">
                  <v-btn
                    icon
                    v-bind="props"
                    color="transparent"
                    variant="flat"
                    size="small"
                    data-cy="rejectTimePeriodCard"
                    @click.stop="$emit('reject')"
                  >
                    <v-icon
                      color="error"
                      size="x-large"
                    >
                      mdi-cancel
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ablehnen</span>
              </v-tooltip>
            </v-col>
            <v-col
              v-if="showStatusButtons"
              cols="auto"
            >
              <v-tooltip location="top">
                <template #activator="{ props }">
                  <v-btn
                    icon
                    v-bind="props"
                    color="transparent"
                    variant="flat"
                    size="small"
                    data-cy="approveTimePeriodCard"
                    @click.stop="$emit('approve')"
                  >
                    <v-icon
                      color="success"
                      size="x-large"
                    >
                      mdi-check
                    </v-icon>
                  </v-btn>
                </template>
                <span> Akzeptieren </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-overlay
          contained
          :model-value="!showStatusButtons && timePeriod.status === 'requested'"
          class="align-center justify-center text-center"
          persistent
          scrim="grey-darken-4"
        >
          <v-chip
            :class="$vuetify.display.xs ? 'px-10 mb-2' : 'mr-4 px-5'"
            variant="flat"
            color="grey-darken-1"
          >
            Angefragt
          </v-chip>
          <v-btn
            icon
            variant="flat"
            size="x-small"
            class="ml-4 mr-4"
            color="primary"
            data-cy="editTimePeriodCard"
            @click="$emit('update')"
          >
            <v-icon> mdi-pencil </v-icon>
          </v-btn>
          <v-btn
            icon
            variant="flat"
            size="x-small"
            color="error"
            data-cy="deleteTimePeriod"
            @click.stop="$emit('delete', timePeriod)"
          >
            <v-icon> mdi-delete </v-icon>
          </v-btn>
        </v-overlay>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    timePeriod: {
      type: Object,
      required: true,
    },
    showStatusButtons: {
      type: Boolean,
    },
    approvals: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['substitute', 'reject', 'approve', 'delete', 'update', 'edit'],
  data() {
    return {
      substituteEntries: [],
      typeDisplayText: {
        PART_TIME: 'Teilzeit',
        ABSENCE: 'Fehlzeit',
        MATERNITY_PROTECTION: 'Mutterschutz',
      },
      substitutableTypes: ['ABSENCE'],
    }
  },
  computed: {
    substituteNames() {
      return [
        ...new Set(this.substituteEntries.map((entry) => this.formatUserName(entry.user))),
      ].join(', ')
    },
  },
  async mounted() {
    const response = await this.$cms.request(
      this.$readItems('schedule_history', {
        filter: {
          _and: [
            {
              end: {
                _gte: this.timePeriod.start,
              },
            },
            {
              start: {
                _lte: this.timePeriod.end || '2099-12-31',
              },
            },
            {
              status: {
                _eq: 'confirmed',
              },
            },
            {
              percentage: {
                _gt: 0,
              },
            },
            {
              overwrite: {
                _eq: true,
              },
            },
            {
              overwrite_time_period: {
                _eq: this.timePeriod.id,
              },
            },
          ],
        },
        fields: [
          'id',
          'status',
          'start',
          'end',
          'user.id',
          'user.gender',
          'user.first_name',
          'user.last_name',
        ],
        sort: ['start'],
      })
    )
    this.substituteEntries = response
  },
  methods: {
    getDotColor(status) {
      if (status === 'past') {
        return 'secondary'
      } else if (status === 'current') {
        return 'success'
      } else {
        return 'primary'
      }
    },
    getColor() {
      switch (this.timePeriod.type) {
        case 'ABSENCE':
        case 'NOT_AVAILABLE':
        case 'TERMINATION':
          return 'error'
        case 'PART_TIME':
        case 'MATERNITY_PROTECTION':
          return 'warning'
        default:
          return 'primary'
      }
    },
  },
}
</script>
<style lang="scss">
.position-top-right {
  position: absolute;
  right: 0;
  top: 0;
}
.schedulehistorycard {
  background-color: rgba(255, 255, 255, 0.1);
}
.biggerFontWeight {
  font-weight: 700;
}
.activehistorycard {
  margin: -6px;
}

.v-timeline-item__body {
  padding-left: 8px !important;
}
</style>
